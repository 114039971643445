<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <svg
        width="26"
        height="15"
        viewBox="0 0 26 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.35714C0 0.60762 0.646707 0 1.44444 0H24.5556C25.3533 0 26 0.60762 26 1.35714C26 2.10667 25.3533 2.71429 24.5556 2.71429H1.44444C0.646707 2.71429 0 2.10667 0 1.35714ZM0 9.5C0 8.75045 0.646707 8.14286 1.44444 8.14286H15.8889C16.6867 8.14286 17.3333 8.75045 17.3333 9.5C17.3333 10.2495 16.6867 10.8571 15.8889 10.8571H1.44444C0.646707 10.8571 0 10.2495 0 9.5ZM0 17.6429C0 16.8933 0.646707 16.2857 1.44444 16.2857H8.66667C9.4644 16.2857 10.1111 16.8933 10.1111 17.6429C10.1111 18.3924 9.4644 19 8.66667 19H1.44444C0.646707 19 0 18.3924 0 17.6429Z"
          fill="#83BE41"
        />
      </svg>
      <!-- <i style="color: #83be41" class="mdi mdi-menu font-24"></i> -->
    </a>
  </li>
  <li class="nav-item d-none d-md-block">
    <div class="nav-link waves-light" style="color: #83be41; font-weight: 600">
      <i class="mdi mdi-map-marker-radius"></i>
      {{ ride?.total_ride_distance }} Distance
    </div>
  </li>
  <li class="nav-item d-none d-md-block">
    <div class="nav-link waves-light" style="color: #83be41; font-weight: 600">
      <i class="mdi mdi-clock"></i> {{ ride?.total_ride_hour }} Time
    </div>
  </li>
  <li class="nav-item d-none d-md-block">
    <div
      class="nav-link waves-light"
      href="javascript:void(0)"
      style="color: #83be41; font-weight: 600"
    >
      <i class="mdi mdi-map-marker"></i>{{ ride?.total_ride }} Ride
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown mt-3"   placement="bottom-right">
        <select class="form-control col-md-5 float-right">
            <option *ngFor="let c of allcountry">{{c.name}} - {{c.currencies[0].code}}</option>
             
        </select>
    </li> -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a
      routerLink="/user/notifications"
      class="nav-link dropdown-toggle waves-effect waves-dark"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <svg
        width="25"
        height="26"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0066 4.495V6.745C14.8857 6.56855 15.7841 6.50704 16.6791 6.562C17.122 6.59438 17.5621 6.65669 17.9966 6.7485V4.495C17.9923 3.96735 17.7802 3.46267 17.4061 3.0905C17.2226 2.90378 17.0038 2.75541 16.7624 2.65404C16.521 2.55266 16.2619 2.5003 16.0001 2.5C15.4717 2.50172 14.9654 2.7125 14.5918 3.08631C14.2183 3.46011 14.0079 3.96656 14.0066 4.495Z"
          fill="#83BE41"
        />
        <path
          d="M15.793 30.4999C16.8342 30.4982 17.8387 30.1151 18.6166 29.423C19.3945 28.7309 19.8918 27.7778 20.0145 26.7439H11.5715C11.6942 27.7779 12.1915 28.7311 12.9694 29.4232C13.7473 30.1153 14.7518 30.4983 15.793 30.4999Z"
          fill="#83BE41"
        />
        <path
          d="M25.8935 23.7035C25.4585 23.1534 25.2212 22.4729 25.22 21.7715V17.095C25.22 12.0615 21.4405 7.87401 16.6145 7.56201C15.3548 7.47785 14.0912 7.65335 12.902 8.07761C11.7129 8.50187 10.6235 9.16586 9.70152 10.0284C8.77952 10.891 8.04451 11.9337 7.54206 13.092C7.0396 14.2503 6.78041 15.4994 6.78055 16.762V21.772C6.77945 22.4736 6.54201 23.1544 6.10655 23.7045L4.48755 25.7445H27.5126L25.8935 23.7035ZM14.7935 10.0745C13.414 10.2639 12.1374 10.9093 11.167 11.908C10.5306 12.5816 10.0569 13.392 9.78205 14.277C9.74227 14.4036 9.65381 14.5093 9.53613 14.5707C9.41845 14.6321 9.28119 14.6443 9.15455 14.6045C9.02791 14.5647 8.92226 14.4763 8.86084 14.3586C8.79942 14.2409 8.78727 14.1036 8.82705 13.977C9.14827 12.9458 9.70083 12.0016 10.4425 11.2165C11.5689 10.055 13.0514 9.30365 14.654 9.08201C14.7854 9.06351 14.9187 9.09794 15.0247 9.17774C15.1306 9.25754 15.2006 9.37616 15.219 9.50751C15.2375 9.63885 15.2031 9.77217 15.1233 9.87813C15.0435 9.98409 14.9249 10.054 14.7935 10.0725V10.0745ZM17.663 9.93501C17.6401 9.95741 17.6149 9.9775 17.588 9.99501C17.5625 10.0147 17.5337 10.0299 17.503 10.04C17.4731 10.0553 17.4408 10.0654 17.4075 10.07C17.3764 10.0758 17.3448 10.0791 17.313 10.08C17.2795 10.0792 17.2461 10.0758 17.213 10.07C17.1817 10.0646 17.1514 10.0545 17.123 10.04C17.091 10.0291 17.0606 10.014 17.0325 9.99501L16.958 9.93501C16.9117 9.88829 16.875 9.83288 16.8502 9.77196C16.8253 9.71104 16.8127 9.64581 16.813 9.58001C16.8123 9.51415 16.8247 9.4488 16.8496 9.38783C16.8745 9.32686 16.9114 9.27149 16.958 9.22501C16.9804 9.20216 17.0055 9.18202 17.0325 9.16501C17.0606 9.14606 17.091 9.13093 17.123 9.12001C17.1514 9.10556 17.1817 9.09545 17.213 9.09001C17.2934 9.07347 17.3765 9.07715 17.4551 9.10072C17.5336 9.12428 17.6051 9.16699 17.663 9.22501C17.7105 9.27114 17.7483 9.32633 17.774 9.38731C17.7998 9.44828 17.8131 9.51381 17.8131 9.58001C17.8131 9.64621 17.7998 9.71173 17.774 9.77271C17.7483 9.83369 17.7105 9.88887 17.663 9.93501Z"
          fill="#83BE41"
        />
      </svg>
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="{{ user.user_image }}"
        alt="user"
        class="rounded-circle"
        width="31"
        height="31"
        onerror="this.src='./assets/images/No-image-found.jpg';"
      />
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <span class="with-arrow">
        <span style="background-color: #83be41"></span>
      </span>
      <div
        style="background-color: #83be41"
        class="d-flex no-block align-items-center p-15 text-white m-b-10"
      >
        <div class="">
          <img
            src="{{ user.user_image }}"
            alt="user"
            class="rounded-circle"
            width="60"
            height="60"
          />
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0">{{ user.name }}</h4>
          <!-- <p class=" m-b-0">{{user.email}}</p> -->
        </div>
      </div>

      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/profile']"
      >
        My Profile</a
      >

      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/business-profile']"
      >
        My Business Profile</a
      >
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/payment-method']"
      >
        Payment method</a
      >

      <!-- <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/weather-setting']"
      >
        Weather Settings
      </a> -->
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        [routerLink]="['/user/subscription']"
      >
        Subscription
        <span class="btn btn-rounded btn-xs btn-success float-right">{{
          activeplan
        }}</span></a
      >
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="planePrice > 0 && isAssetsRegistered"
        [routerLink]="['/user/operation']"
      >
        On Boarding
      </a>
      <a class="dropdown-item" href="javascript:void(0)" *ngIf="planePrice > 0">
        {{ user.business_detail.active ? "Renew On" : "Expired On" }}
        {{ renewon | date : "medium" }}
        <b>{{
          user.business_detail.active
            ? user.subscription.currency.code +
              " " +
              user.subscription.price +
              "/" +
              user.subscription.timing
            : ""
        }}</b>
      </a>
      <a class="dropdown-item" href="javascript:void(0)" *ngIf="planePrice > 0">
        <div>
          Commission :-
          <b> {{ commission }}%</b>
        </div>
      </a>
      <a
        class="dropdown-item text-danger"
        href="javascript:void(0)"
        *ngIf="planePrice > 0 && user.business_detail.active"
        (click)="openModal(confirmModel, 'Cancel')"
      >
        Cancel plan
      </a>

      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item text-danger"
        href="javascript:void(0)"
        (click)="openModal(confirmModel, 'Remove')"
      >
        <b>Remove account</b></a
      >
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
        Logout</a
      >
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>

<ng-template #confirmModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'Remove account completely'">
    Your existing data will be removed and can not be recovered.
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">Close</button> -->
      <button type="submit" class="btn btn-danger" (click)="removeAccount()">
        Remove
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="modeltitle == 'Cancel plan'">
    Are you sure you want to cancel your subscription?. You won't be charge from
    next renewal cycle.
    <div class="modal-footer">
      <button type="submit" class="btn btn-danger" (click)="cancelPlan()">
        Yes
      </button>
    </div>
  </div>
</ng-template>
