import { HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { DatePipe } from "@angular/common";
import { Globals } from "../../common/global";

import * as moment from "moment";

@Component({
  selector: "app-coupon-code",
  templateUrl: "./coupon-code.component.html",
  styleUrls: ["./coupon-code.component.css"],
})
export class CouponCodeComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  EmailData: any;
  modeltitle: any;
  searchresult: any;
  model1: any;
  listData: any;
  data: any;
  detail: any;
  detail1 = [];
  operatorDetails = this.Globals.getOperatorDetails();
  length: number = 0;
  urls: any[] = [];
  today: any = moment(new Date()).format("YYYY-MM-DD");

  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private fb: FormBuilder,
    public Globals: Globals
  ) {}

  ngOnInit(): void {
    this.search();
    this.getCouponList();

    // this.specialityform = this.fb.group({
    //   couponCode: ["", Validators.required],
    //   expire_date: ["", Validators.required],
    //   limit: ["", Validators.required, Validators.min(1)],
    //   discountPercent: [
    //     "",
    //     Validators.required,
    //     Validators.min(1),
    //     Validators.max(100),
    //   ],
    //   isMultipleUse: ["", Validators.required],
    // });
  }

  deleteimage(img: any) {
    this.urls.splice(img, 1);
  }
  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.searchresult = [];
    this.model1 = "";
    this.modeltitle = title;

    this.detail = Object.assign({}, data);
    if (this.modeltitle == "Create Coupon Code") {
      this.detail.isExclusive = true;
    }

    this.detail.expire_date = moment(
      this.detail.expire_date ?? new Date()
    ).format("YYYY-MM-DD");

    console.log(this.detail);
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    this.urls = [];
  }
  search() {
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.REQUEST_PAYMENT.EMAIL_GET +
          "search_users?search=" +
          this.model1
      )
      .subscribe((res) => {
        if (this.model1 !== "") {
          this.searchresult = res.userslists;
        } else {
          this.searchresult = [];
        }
      });
  }

  openImageModal(imageModel: NgbModal, detail: any) {
    this.detail1 = detail?.urls;
    console.log(this.detail1);

    this.modalService.open(imageModel, {
      centered: true,
      backdrop: "static",
    });
  }
  validateLimit(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/^[0-9]+$/.test(inputChar) && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  validateDiscount(): void {
    if (this.detail.discountPercent < 0) {
      this.detail.discountPercent = 0;
    } else if (this.detail.discountPercent > 100) {
      this.detail.discountPercent = 100;
    }
  }
  onSubmit(f: any) {
    const operator_id = this.data?._id;

    const uploadData = new FormData();
    let payload: any = {
      couponCode: f.value.couponCode,
      expire_date: f.value.expire_date,
      limit: f.value.limit,
      discountPercent: f.value.discountPercent,
      isMultipleUse: f.value.isMultipleUse,
    };
    if (this.modeltitle == "Edit Coupon Code") {
      let url = ConfigApi.URLS.COUPONCODE.UPDATE + "/" + this.detail._id;
      let method = "PUT";
      this.apiCallingService.callAPI(payload, method, url).subscribe((res) => {
        console.log(res);
        this.ngOnInit();
        this.closeBtnClick();
      });
    } else {
      payload.operator_id = this.Globals.getUser()._id;

      let url = ConfigApi.URLS.COUPONCODE.CREATE;
      let method = "POST";
      this.apiCallingService.callAPI(payload, method, url).subscribe((res) => {
        console.log(res);
        this.ngOnInit();
        this.closeBtnClick();
      });
    }
  }
  getCouponList() {
    const data: any = localStorage.getItem("Auth-user");
    const id = JSON.parse(data);
    const id1 = id?._id;
    console.log(id1);

    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.COUPONCODE.LIST)
      .subscribe((res) => {
        this.listData = res?.data;
        console.log(this.listData);
      });
  }
  deleteData(id: any) {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.COUPONCODE.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        console.log(res);
        this.closeBtnClick();
        this.ngOnInit();
      });
  }
  OnClick(d: any) {
    this.data = d;
    this.searchresult = [];
    console.log(this.data);
  }
}
