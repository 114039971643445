<div class="weather-container">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h4 class="card-title mb-0">
      <span class="lstick"></span>Weather Settings
    </h4>
    <div>
      <a
        href="javascript: void(0);"
        class="btn btn-primary m-2"
        placement="top"
        (click)="openCreateModal()"
      >
        Update settings
      </a>
    </div>
  </div>

  <div class="weather-panel">
    <div *ngIf="hasWeatherData()">
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-4ths">
          <div class="card mb-4">
            <div class="card-body d-flex align-items-center">
              <div
                class="icon-container temp d-flex align-items-center justify-content-center me-3"
              >
                <i class="fas fa-thermometer-half"></i>
              </div>
              <div class="info-container ml-2">
                <h3 class="mb-1">Minimum Temperature</h3>
                <p class="mb-0">
                  {{
                    WeatherData.minimumTemperature
                      ? WeatherData.minimumTemperature
                      : "-"
                  }}
                  {{ WeatherData.minimumTemperature ? "°C" : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-5ths">
          <div class="card mb-4">
            <div class="card-body d-flex align-items-center">
              <div
                class="icon-container temp d-flex align-items-center justify-content-center me-3"
              >
                <i class="fas fa-thermometer-full"></i>
              </div>
              <div class="info-container ml-2">
                <h3 class="mb-1">Maximum Temperature</h3>
                <p class="mb-0">
                  {{
                    WeatherData.maximumTemperature
                      ? WeatherData.maximumTemperature
                      : "-"
                  }}
                  {{ WeatherData.maximumTemperature ? "°C" : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-5ths">
          <div class="card mb-4">
            <div class="card-body d-flex align-items-center">
              <div
                class="icon-container wind d-flex align-items-center justify-content-center me-3"
              >
                <i class="fas fa-wind"></i>
              </div>
              <div class="info-container ml-2">
                <h3 class="mb-1">Max Wind Speed</h3>
                <div class="d-flex justify-content-between card-data">
                  <p class="mb-0">
                    {{
                      WeatherData.maxWindSpeed ? WeatherData.maxWindSpeed : "-"
                    }}
                    {{ WeatherData.maxWindSpeed ? "m/s" : "" }}
                  </p>
                  <p *ngIf="WeatherData.maxWindSpeed" class="mb-0">
                    {{ WeatherData.isWindSpeedRequired ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-5ths">
          <div class="card mb-4">
            <div class="card-body d-flex align-items-center">
              <div
                class="icon-container sea d-flex align-items-center justify-content-center me-3"
              >
                <i class="fas fa-water"></i>
              </div>
              <div class="info-container ml-2">
                <h3 class="mb-1">Water Level (m³/s)</h3>
                <div class="d-flex justify-content-between card-data">
                  <p class="mb-0">
                    {{
                      WeatherData.maxSeaLevelInMeters
                        ? WeatherData.maxSeaLevelInMeters
                        : "-"
                    }}
                    {{ WeatherData.maxSeaLevelInMeters ? "(m³/s)" : "" }}
                  </p>
                  <p *ngIf="WeatherData.maxSeaLevelInMeters" class="mb-0 fs-6">
                    {{ WeatherData.isSeaLevelRequired ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-5ths">
          <div class="card mb-4">
            <div class="card-body d-flex align-items-center">
              <div
                class="icon-container sunset d-flex align-items-center justify-content-center me-3"
              >
                <i
                  [class]="
                    'fas fa-' +
                    (WeatherData.afterSunsetRecommended ? 'moon' : 'sun')
                  "
                  style="color: #ffa726"
                ></i>
              </div>
              <div class="info-container ml-2">
                <h3 class="mb-1">Block Rental Sunset</h3>
                <p class="mb-0 sunset-text" style="color: #ffa726">
                  {{
                    WeatherData.afterSunsetRecommended !== null
                      ? WeatherData.afterSunsetRecommended
                        ? "Yes"
                        : "No"
                      : "-"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #createModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="createUserLabel">{{ modeltitle }}</h5>
      <button
        type="button"
        class="close"
        (click)="closeBtnClick()"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="d-flex">
          <input
            type="checkbox"
            [(ngModel)]="isMinimumTemperature"
            id="isMinimumTemperature"
            class="inputCheckbox"
          />
          <label for="minimumTemperatureCreate"
            >Minimum Temperature (°C):</label
          >
        </div>
        <input
          type="number"
          id="minimumTemperatureCreate"
          [(ngModel)]="WeatherData.minimumTemperature"
          [disabled]="!isMinimumTemperature"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <div class="d-flex">
          <input
            type="checkbox"
            [(ngModel)]="isMaximumTemperature"
            id="isMaximumTemperature"
            class="inputCheckbox"
          />
          <label for="maximumTemperatureCreate"
            >Maximum Temperature (°C):</label
          >
        </div>
        <input
          type="number"
          id="maximumTemperatureCreate"
          [(ngModel)]="WeatherData.maximumTemperature"
          [disabled]="!isMaximumTemperature"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <div>
          <div class="d-flex">
            <input
              type="checkbox"
              [(ngModel)]="isMaxWindSpeed"
              id="isMaxWindSpeed"
              class="inputCheckbox"
            />
            <label for="maxWindSpeedCreate">Max Wind Speed (m/s): </label>
          </div>
        </div>
        <input
          type="number"
          id="maxWindSpeedCreate"
          [(ngModel)]="WeatherData.maxWindSpeed"
          [disabled]="!isMaxWindSpeed"
          class="form-control"
        />
        <div *ngIf="isMaxWindSpeed" class="d-flex align-items-center mt-2">
          <p class="mb-2">Block rental purchase?</p>
          <label class="mx-2">
            <input
              type="radio"
              name="windSpeedRequired"
              [(ngModel)]="windSpeedRequiredOption"
              [value]="true"
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="windSpeedRequired"
              [(ngModel)]="windSpeedRequiredOption"
              [value]="false"
            />
            No
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="d-flex align-items-center">
          <div class="d-flex">
            <input
              type="checkbox"
              [(ngModel)]="isMaxSeaLevelInMeters"
              id="isMaxSeaLevelInMeters"
              class="inputCheckbox"
            />
            <label for="maxSeaLevelInMetersCreate"
              >Max Water Level (m³/s):</label
            >
          </div>
        </div>
        <input
          type="number"
          id="maxSeaLevelInMetersCreate"
          class="form-control"
          [(ngModel)]="WeatherData.maxSeaLevelInMeters"
          [disabled]="!isMaxSeaLevelInMeters"
        />

        <div
          *ngIf="isMaxSeaLevelInMeters"
          class="d-flex align-items-center mt-2"
        >
          <p class="mb-2">Block rental purchase?</p>
          <label class="mx-2">
            <input
              type="radio"
              name="seaLevelRequired"
              [(ngModel)]="seaLevelRequiredOption"
              [value]="true"
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="seaLevelRequired"
              [(ngModel)]="seaLevelRequiredOption"
              [value]="false"
            />
            No
          </label>
        </div>
      </div>

      <div class="form-group checkbox-group">
        <div class="text-left d-flex align-items-center">
          <input
            type="checkbox"
            [(ngModel)]="isAfterSunsetRecommended"
            id="isAfterSunsetRecommended"
            class="inputCheckbox ml-0"
          />
          <label for="afterSunsetRecommended" class="mb-0"
            >After Sunset Recommended ?</label
          >

          <div
            *ngIf="isAfterSunsetRecommended"
            class="d-flex align-items-center ml-3"
          >
            <ui-switch
              [checked]="WeatherData.afterSunsetRecommended"
              (change)="WeatherData.afterSunsetRecommended = $event"
              [disabled]="!isAfterSunsetRecommended"
            >
              After Sunset Recommended
            </ui-switch>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="isAfterSunsetRecommended">
        <div class="text-left d-flex align-items-center">
          <p class="mb-0">Block rental purchase?</p>

          <div
            *ngIf="isAfterSunsetRecommended"
            class="d-flex align-items-center ml-3"
          >
            <label class="mx-2 mb-0">
              <input
                type="radio"
                name="isSunsetRequired"
                [(ngModel)]="WeatherData.isSunsetRequired"
                [value]="true"
              />
              Yes
            </label>
            <label class="mb-0">
              <input
                type="radio"
                name="isSunsetRequired"
                [(ngModel)]="WeatherData.isSunsetRequired"
                [value]="false"
              />
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="closeBtnClick()">
        Cancel
      </button>
      <button (click)="createWeatherSettings()" class="btn btn-primary">
        Create
      </button>
    </div>
  </ng-template>
</div>
