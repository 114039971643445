import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import is from "date-fns/esm/locale/is";

interface WeatherSettings {
  minimumTemperature: number | null;
  maximumTemperature: number | null;
  maxWindSpeed: number | null;
  isWindSpeedRequired: boolean;
  maxSeaLevelInMeters: number | null;
  isSeaLevelRequired: boolean;
  afterSunsetRecommended: boolean;
  isSunsetRequired: boolean;
}
@Component({
  selector: "app-weather-settings",
  templateUrl: "./weather-settings.component.html",
  styleUrls: ["./weather-settings.component.css"],
})
export class WeatherSettingsComponent implements OnInit {
  userDetails: any;
  WeatherData: WeatherSettings = {
    minimumTemperature: null,
    maximumTemperature: null,
    maxWindSpeed: null,
    isWindSpeedRequired: true,
    maxSeaLevelInMeters: null,
    isSeaLevelRequired: true,
    afterSunsetRecommended: true,
    isSunsetRequired: true,
  };
  isMaxWindSpeed = false;
  isMinimumTemperature = false;
  isMaximumTemperature = false;
  isAfterSunsetRecommended = false;
  isMaxSeaLevelInMeters = false;
  windSpeedRequiredOption: boolean = true;
  seaLevelRequiredOption: boolean = true;
  // SunsetRequiredOption = false;

  loading: boolean = false;

  modeltitle: string = "";

  @ViewChild("createModal") createModal!: TemplateRef<any>;

  get isDisabled(): boolean {
    return (
      !this.isMaxWindSpeed &&
      !this.isMinimumTemperature &&
      !this.isMaximumTemperature &&
      !this.isAfterSunsetRecommended &&
      !this.isMaxSeaLevelInMeters
      // &&
      // !this.SunsetRequiredOption
    );
  }

  constructor(
    private apiService: ApiCallingService,
    private modalService: NgbModal
  ) {
    let data = localStorage.getItem("Auth-user");
    if (!data) {
      return;
    }
    try {
      this.userDetails = JSON.parse(data);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    if (this.userDetails) {
      this.getWeatherDetails();
    }
  }

  hasWeatherData(): boolean {
    return (
      this.WeatherData !== null && Object.keys(this.WeatherData).length > 0
    );
  }

  getWeatherDetails() {
    this.apiService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.WeatherSettings.GET_WEATHER_DETAILS +
          "?user_id=" +
          this.userDetails._id
      )
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.WeatherData.maxWindSpeed = res.data.maxWindSpeed;
            this.WeatherData.minimumTemperature = res.data.minimumTemperature;
            this.WeatherData.maximumTemperature = res.data.maximumTemperature;
            this.WeatherData.afterSunsetRecommended =
              res.data.afterSunsetRecommended;
            this.WeatherData.maxSeaLevelInMeters = res.data.maxSeaLevelInMeters;
            this.WeatherData.isWindSpeedRequired = res.data.isWindSpeedRequired;
            this.WeatherData.isSeaLevelRequired = res.data.isSeaLevelRequired;
            this.WeatherData.isSunsetRequired = res.data.isSunsetRequired;

            this.isMaxWindSpeed = res.data.maxWindSpeed !== null;
            this.isMinimumTemperature = res.data.minimumTemperature !== null;
            this.isMaximumTemperature = res.data.maximumTemperature !== null;
            this.isAfterSunsetRecommended =
              res.data.afterSunsetRecommended !== null;

            this.isMaxSeaLevelInMeters = res.data.maxSeaLevelInMeters !== null;
          } else {
            this.WeatherData = {
              minimumTemperature: null,
              maximumTemperature: null,
              maxWindSpeed: null,
              isWindSpeedRequired: true,
              maxSeaLevelInMeters: null,
              isSeaLevelRequired: true,
              afterSunsetRecommended: true,
              isSunsetRequired: true,
            };
          }
          console.log(this.WeatherData, "this.WeatherData");
        },
        (error: any) => {
          console.error("Error fetching weather details:", error);
          this.WeatherData = {
            minimumTemperature: null,
            maximumTemperature: null,
            maxWindSpeed: null,
            isWindSpeedRequired: true,
            maxSeaLevelInMeters: null,
            isSeaLevelRequired: true,
            afterSunsetRecommended: true,
            isSunsetRequired: true,
          };
        }
      );
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }

  openModal(content: TemplateRef<any>, data: any, title: string) {
    this.modeltitle = title;

    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
    });
  }

  openCreateModal() {
    this.isMaxWindSpeed = this.WeatherData.maxWindSpeed !== null;
    this.isMinimumTemperature = this.WeatherData.minimumTemperature !== null;
    this.isMaximumTemperature = this.WeatherData.maximumTemperature !== null;
    this.isAfterSunsetRecommended =
      this.WeatherData.afterSunsetRecommended !== null;
    if (this.WeatherData.afterSunsetRecommended === null) {
      this.WeatherData.afterSunsetRecommended = true;
    }
    this.isMaxSeaLevelInMeters = this.WeatherData.maxSeaLevelInMeters !== null;
    this.windSpeedRequiredOption = this.WeatherData.isWindSpeedRequired;
    this.seaLevelRequiredOption = this.WeatherData.isSeaLevelRequired;

    this.openModal(this.createModal, {}, "Create Weather Settings");
  }

  createWeatherSettings() {
    this.WeatherData.isWindSpeedRequired = this.windSpeedRequiredOption;
    this.WeatherData.isSeaLevelRequired = this.seaLevelRequiredOption;

    let payload: any = {
      maxWindSpeed: this.isMaxWindSpeed ? this.WeatherData.maxWindSpeed : null,
      minimumTemperature: this.isMinimumTemperature
        ? this.WeatherData.minimumTemperature
        : null,
      maximumTemperature: this.isMaximumTemperature
        ? this.WeatherData.maximumTemperature
        : null,
      afterSunsetRecommended: this.isAfterSunsetRecommended
        ? this.WeatherData.afterSunsetRecommended
        : null,
      isSunsetRequired: this.isAfterSunsetRecommended
        ? this.WeatherData.isSunsetRequired
        : null,
      maxSeaLevelInMeters: this.isMaxSeaLevelInMeters
        ? this.WeatherData.maxSeaLevelInMeters
        : null,
      isWindSpeedRequired: this.isMaxWindSpeed
        ? this.WeatherData.isWindSpeedRequired
        : null,
      isSeaLevelRequired: this.isMaxSeaLevelInMeters
        ? this.WeatherData.isSeaLevelRequired
        : null,
      user_id: this.userDetails._id,
    };

    const apiUrl = ConfigApi.URLS.WeatherSettings.CREATE_WEATHER_SETTINGS;

    try {
      this.apiService.callAPI(payload, "POST", apiUrl).subscribe(
        (res: any) => {
          if (res) {
            this.getWeatherDetails();
          }
        },
        (error: any) => {
          console.error("Error creating weather settings:", error);
        }
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.closeBtnClick();
    }
  }
}
