<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <div class="">
                <h4 class="card-title mb-0">
                    <span class="lstick"></span>Coupon Code For All Packages
                </h4>
                <div class="d-flex mt-3">
                    <a href="javascript: void(0);" class="btn btn-primary ml-auto" placement="top"
                        (click)="openModal(AddModal, {}, 'Create Coupon Code')" ngbTooltip="Create Coupon Code">
                        + Create
                    </a>
                </div>

                <div class="">
                    <table class="table table-striped border mt-4">
                        <thead>
                            <tr>
                                <!-- <th scope="col">Image</th> -->
                                <th scope="col">Coupon Code</th>
                                <th scope="col">Limit</th>
                                <th scope="col">Discount Percentage</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Expire Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of listData">
                                <td>{{d.couponCode}}</td>
                                <td>{{d.limit}}</td>
                                <td>{{d.discountPercent}}</td>
                                <td>{{d.created_at | date : "yyyy-MM-dd hh:mm a"}}</td>
                                <td>{{d.expire_date | date : "yyyy-MM-dd hh:mm a"}}</td>
                                <td>
                                    <a href="javascript: void(0);" class="link mr-2" placement="top"
                                        (click)="openModal(AddModal, d, 'Edit Coupon Code')" ngbTooltip="Edit">
                                        <i class="fa fa-edit"></i>
                                    </a>
                                    <a href="javascript: void(0);" class="link mr-2" placement="top"
                                        (click)="openModal(AddModal, d, 'Delete Coupon Code')" ngbTooltip="Delete">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #AddModal let-modal>
    <div class="p-3">
        <div class="modal-header">
            <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
            <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <form *ngIf="modeltitle == 'Create Coupon Code' || modeltitle == 'Edit Coupon Code'"
                (ngSubmit)="onSubmit(specialityform)" #specialityform="ngForm">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Coupon Code<br />

                            <small *ngIf="modeltitle == 'Create Coupon Code'">Create multiple coupon code using
                                semi-colon
                                <b>Ex.(c1;c2)</b></small></label>
                        <div class="col-sm-7"><input type="text" class="form-control" name="couponCode"
                                pattern="(\s*[^\s]+\s*)+" [(ngModel)]="detail.couponCode" id="couponCode" ngModel
                                #couponCode="ngModel" required placeholder="Enter Coupon Code" /></div>


                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Limit</label>
                        <div class="col-sm-7">
                            <input type="number" class="form-control" name="limit" (keypress)="validateLimit($event)"
                                [(ngModel)]="detail.limit" pattern="(\s*[^\s]+\s*)+" min="1" id="limit" name="limit"
                                required />
                            <div *ngIf="detail.limit < 1" class="text-danger">
                                Limit must be greater than 0.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Discount Percentage</label>
                        <div class="col-sm-7">
                            <input type="number" class="form-control" name="discountPercent"
                                [(ngModel)]="detail.discountPercent" (input)="validateDiscount()" min="0" max="100"
                                id="discountPercent" required />
                            <div *ngIf="detail.discountPercent < 0 && detail.discountPercent > 100" class="text-danger">
                                Discount must be between 0 and 100%.
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-form-label">Expire Date</label>
                        <div class="col-sm-7">
                            <input type="date" class="form-control" [(ngModel)]="detail.expire_date" name="expire_date"
                                id="expire_date" ngModel #expire_date="ngModel" required />

                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 col-form-label" style="padding: 0 34px;">
                            <input type="checkbox" id="isMultipleUse" name="isMultipleUse"
                                [(ngModel)]="detail.isMultipleUse" class="form-check-input" />
                            <label class="form-check-label" for="isMultipleUse">
                                Have Multiple Use
                            </label>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
                        Close
                    </button>

                    <button type="submit" class="btn btn-secondary btn-raised mr-1" [disabled]="!specialityform.valid">
                        Submit
                    </button>
                </div>
            </form>
            <form [formGroup]="editUser" *ngIf="modeltitle == 'Delete Coupon Code'">
                Are you sure want to delete?
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeBtnClick()">
                        Close
                    </button>
                    <button type="submit" class="btn btn-danger" (click)="deleteData()">
                        Delete
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #imageModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="lockerImageLabel"> Images</h5>
        <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div>
                <img width="100%" style="margin-top: 10px" />
            </div>
        </div>
    </div>
    <div></div>
</ng-template>