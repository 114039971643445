<div class="row">
  <div class="col-12">
    <app-weather-settings></app-weather-settings>
  </div>
  <div class="col-12">
    <div class="card card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title mb-0">
          <span class="lstick"></span>Warning List
        </h4>
        <div>
          <a
            href="javascript: void(0);"
            class="btn btn-primary m-2"
            placement="top"
            (click)="openModal(editModal, {}, 'Create Warning')"
          >
            + Create
          </a>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped border mt-4">
          <thead>
            <tr>
              <th scope="col">Risk Title</th>
              <th scope="col">Risk Description</th>
              <th scope="col">Username</th>
              <th scope="col">User Type</th>
              <th scope="col">Address</th>
              <th scope="col">Created At</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td>{{ item.riskTitle }}</td>
              <td>{{ item.riskDescription }}</td>
              <td>
                {{
                  item?.user_id && item.user_id.username
                    ? item.user_id.username
                    : "-"
                }}
              </td>
              <td>{{ item.user_type | titlecase }}</td>
              <td>
                <div [ngbTooltip]="item.address">
                  {{ truncateText(item.address, 50) }}
                </div>
              </td>
              <td>{{ item.created_at | date : "medium" }}</td>
              <td>
                <a
                  href="javascript: void(0);"
                  class="link mr-1"
                  placement="top"
                  ngbTooltip="Edit"
                  (click)="openModal(editModal, item, 'Edit Warning')"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript: void(0);"
                  class="link mr-2"
                  placement="top"
                  (click)="openModal(editModal, item, 'Delete Warning')"
                  ngbTooltip="Delete"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>

            <tr *ngIf="data.length == 0">
              <td colspan="6" class="text-center">No data found</td>
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex justify-content-center mt-5"
          *ngIf="data.length != 0"
        >
          <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalcount"
            [maxSize]="7"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
            (pageChange)="loadPage($event)"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">{{ modeltitle }}</h5>
    <button
      type="button"
      class="close"
      (click)="closeBtnClick()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      *ngIf="modeltitle == 'Create Warning' || modeltitle == 'Edit Warning'"
      (ngSubmit)="onSubmit(specialityform)"
      #specialityform="ngForm"
    >
      <div class="form-group row">
        <label for="riskTitle" class="col-sm-4 col-form-label"
          >Risk Title</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            name="riskTitle"
            [(ngModel)]="detail.riskTitle"
            id="riskTitle"
            ngModel
            #riskTitle="ngModel"
            required
          />
          <div
            *ngIf="
              riskTitle.invalid &&
              (riskTitle.dirty || riskTitle.touched || formSubmitted)
            "
            class="text-danger"
          >
            <div *ngIf="riskTitle.errors?.required">
              Risk Title is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="riskDescription" class="col-sm-4 col-form-label"
          >Risk Description</label
        >
        <div class="col-sm-8">
          <textarea
            class="form-control"
            name="riskDescription"
            [(ngModel)]="detail.riskDescription"
            id="riskDescription"
            ngModel
            #riskDescription="ngModel"
            required
          ></textarea>
          <div
            *ngIf="
              riskDescription.invalid &&
              (riskDescription.dirty ||
                riskDescription.touched ||
                formSubmitted)
            "
            class="text-danger"
          >
            <div *ngIf="riskDescription.errors?.required">
              Risk Description is required.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="map" class="col-sm-12 col-form-label">Location</label>
        <div class="col-sm-12">
          <div id="map" style="width: 100%; height: 300px"></div>
          <div *ngIf="addressRequiredError" class="text-danger mt-2">
            Location is required
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-primary btn-raised mr-1">
          Submit
        </button>
      </div>
    </form>
    <form [FormGroup]="editUser" *ngIf="modeltitle == 'Delete Warning'">
      Are you sure want to Delete this Warning ?
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeBtnClick()"
        >
          Close
        </button>
        <button type="submit" class="btn btn-danger" (click)="deleteData()">
          Delete
        </button>
      </div>
    </form>
  </div>
</ng-template>
