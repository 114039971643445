import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigApi } from "src/app/config-api";
import { ApiCallingService } from "src/app/services/api-calling.service";
import { environment } from "src/environments/environment";
import * as mapboxgl from "mapbox-gl";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-warning-feature",
  templateUrl: "./warning-feature.component.html",
  styleUrls: ["./warning-feature.component.css"],
})
export class WarningFeatureComponent implements OnInit {
  private mapboxAccessToken = environment.mapbox.accessToken;
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);
  operatorDetails: any = {};
  page = 0;
  pageSize = 10;
  data = [];
  totalcount = 0;
  modeltitle: any;
  detail: any = {};
  map: any;
  lat: number = 0;
  lng: number = 0;
  formSubmitted = false;
  addressRequiredError: boolean = false;

  constructor(
    private apiCallingService: ApiCallingService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let operator = localStorage.getItem("operator_details");
    if (operator) {
      try {
        this.operatorDetails = JSON.parse(operator);
      } catch (e) {
        console.error("Failed to parse operator details:", e);
      }
    }
    this.getList();
  }

  getList() {
    let page = this.page == 0 ? 0 : this.page - 1;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.warningFeature.WARNING_LIST +
          "?operator_id=" +
          this.operatorDetails._id +
          "&page=" +
          page +
          "&limit=" +
          this.pageSize
      )
      .subscribe(
        (res) => {
          if (this.apiCallingService.handleSuccess(res)) {
            console.log(res, "res");
            this.data = res.data;
            this.totalcount = res.total;
          } else {
            console.log(res.msg);
          }
        },
        (err) => {
          console.log(err.error.msg);
        }
      );
  }

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.detail = Object.assign({}, data);
    this.modeltitle = title;
    this.formSubmitted = false;

    if (
      title === "Edit Warning" &&
      this.detail.latitude &&
      this.detail.longitude
    ) {
      this.lat = this.detail.latitude;
      this.lng = this.detail.longitude;
    }

    setTimeout(() => {
      if (title === "Create Warning") {
        this.initializeMap("create");
      }
    }, 300);
    setTimeout(() => {
      if (title === "Edit Warning") {
        this.initializeMap("edit");
        const geocoderInput: any = document.querySelector(
          ".mapboxgl-ctrl-geocoder--input"
        );
        if (geocoderInput) {
          geocoderInput.value = this.detail.address;
        }
      }
    }, 300);
  }

  closeBtnClick() {
    this.modalService.dismissAll();
    this.addressRequiredError = false;
  }

  onSubmit(form: NgForm) {
    this.formSubmitted = true;

    if (!this.lat || this.lat === 0 || !this.lng || this.lng === 0) {
      this.addressRequiredError = true;
      return;
    }

    if (form.valid && !this.addressRequiredError) {
      const payload = {
        riskTitle: this.detail.riskTitle,
        riskDescription: this.detail.riskDescription,
        latitude: this.lat,
        longitude: this.lng,
        user_id: this.operatorDetails._id,
        // operator_id: this.operatorDetails._id,
        user_type: "operator",
      };

      if (this.modeltitle === "Create Warning") {
        this.apiCallingService
          .callAPI(
            payload,
            "POST",
            ConfigApi.URLS.warningFeature.CREATE_WARNING
          )
          .subscribe(
            (res) => {
              if (this.apiCallingService.handleSuccess(res)) {
                this.toastr.success(res.msg);
                this.getList();
                this.closeBtnClick();
              } else {
                console.log(res.msg);
              }
            },
            (err) => {
              console.log(err.error.msg);
            }
          );
      } else if (this.modeltitle === "Edit Warning") {
        this.apiCallingService
          .callAPI(
            payload,
            "PUT",
            ConfigApi.URLS.warningFeature.UPDATE_WARNING + "/" + this.detail._id
          )
          .subscribe(
            (res) => {
              if (this.apiCallingService.handleSuccess(res)) {
                this.toastr.success(res.msg);
                this.getList();
                this.closeBtnClick();
              } else {
                console.log(res.msg);
              }
            },
            (err) => {
              console.log(err.error.msg);
            }
          );
      }
    }
  }

  initializeMap(type: string) {
    (mapboxgl as any).accessToken = this.mapboxAccessToken;
    const initialLat = type === "edit" ? this.lat : 0;
    const initialLng = type === "edit" ? this.lng : 0;

    try {
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [initialLng, initialLat],
        zoom: 12,
      });

      const geocoder = new (MapboxGeocoder as any)({
        accessToken: (mapboxgl as any).accessToken,
        mapboxgl: mapboxgl,
        placeholder: "Search for location",
        marker: false,
      });

      this.map.addControl(geocoder);

      const marker = new mapboxgl.Marker({ draggable: true })
        .setLngLat([initialLng, initialLat])
        .addTo(this.map);

      this.map.flyTo({
        center: [initialLng, initialLat],
        zoom: this.map.getZoom(),
      });

      marker.on("dragend", () => {
        const lngLat = marker.getLngLat();
        this.lat = lngLat.lat;
        this.lng = lngLat.lng;
        this.addressRequiredError = false;
        this.reverseGeocode(lngLat);
      });

      this.map.on("click", (e: any) => {
        this.lat = e.lngLat.lat;
        this.lng = e.lngLat.lng;
        marker.setLngLat([this.lng, this.lat]);
        this.addressRequiredError = false;
        this.reverseGeocode(e.lngLat);
      });

      geocoder.on("result", (event: any) => {
        const coordinates = event.result.center;
        this.lng = coordinates[0];
        this.lat = coordinates[1];
        marker.setLngLat(coordinates);
        this.addressRequiredError = false;
        const geocoderInput: any = document.querySelector(
          ".mapboxgl-ctrl-geocoder--input"
        );
        if (geocoderInput) {
          geocoderInput.value = event.result.place_name;
        }
      });
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  }

  deleteData() {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.warningFeature.DELETE_WARNING + "/" + this.detail._id
      )
      .subscribe(
        (res) => {
          console.log(
            "🚀 ~ file: advance-booking.component.ts:772 ~ AdvanceBookingComponent ~ .subscribe ~ res:",
            res
          );
          if (this.apiCallingService.handleSuccess(res)) {
            this.toastr.success(res.msg);
            this.closeBtnClick();
            this.ngOnInit();
          }
        },
        (err) => {
          this.closeBtnClick();
          this.ngOnInit();
          this.toastr.error(err.error.msg, "Error");
        }
      );
  }

  truncateText(text: string, length: any) {
    return text.slice(0, length) + (text.length > length ? "..." : "");
  }

  loadPage(page: number) {
    if (page) {
      this.page = page;
      this.getList();
    }
  }

  reverseGeocode(lngLat: any) {
    const geocoderInput: any = document.querySelector(
      ".mapboxgl-ctrl-geocoder--input"
    );
    if (geocoderInput) {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${this.mapboxAccessToken}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data.features.length > 0) {
            geocoderInput.value = data.features[0].place_name;
          } else {
            geocoderInput.value = "No address found";
          }
        })
        .catch((error) => {
          console.error("Error reverse geocoding:", error);
          geocoderInput.value = "Error retrieving address";
        });
    }
  }
}
