import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";

import { ApiCallingService } from "../../services/api-calling.service";
import { ConfigApi } from "../../config-api";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { Globals } from "../../common/global";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-sale-package",
  templateUrl: "./sale-package.component.html",
  styleUrls: ["./sale-package.component.css"],
})
export class SalePackageComponent implements OnInit {
  @ViewChild("specialityform", { static: true }) specialityform: NgForm =
    Object.create(null);

  operatorDetails = this.Globals.getOperatorDetails();
  pId: any;
  constructor(
    private modalService: NgbModal,
    private apiCallingService: ApiCallingService,
    private toastr: ToastrService,
    public Globals: Globals,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getLockTypes();
  }
  modeltitle: any;
  page = 1;
  pageSize = 10;
  data = [];
  totalcount = 0;
  detail: any;
  locktypes = [];
  timings = ["Day", "Week", "Month", "3 Month", "6 Month", "Yearly"];
  currency: any = localStorage.getItem("CUR");

  openModal(targetModal: NgbModal, data: any, title: any) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: "static",
    });
    this.modeltitle = title;
    this.detail = Object.assign({}, data);

    // input.substring(0, string) + "...";
    if (this.modeltitle == "Edit Package") {
      this.detail.pkg_desc =
        this.detail.pkg_desc == "undefined" ? "" : this.detail.pkg_desc;
    }
    if (Object.keys(data).length == 0) {
      this.detail.isExclusive = false;
      this.detail.type = this.locktypes[0];
      this.detail.vat = 6.0;
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  getList() {
    let user_id = this.Globals.getUser()._id;
    this.apiCallingService
      .callAPI(
        {},
        "GET",
        ConfigApi.URLS.SALE_PACKAGE.LIST + "?user_id=" + user_id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.data = res.data;
          this.totalcount = res.data.count;
        }
      });
  }

  getLockTypes() {
    let user_id = this.Globals.getUser()._id;
    let query = "?user_id=" + user_id;
    this.apiCallingService
      .callAPI({}, "GET", ConfigApi.URLS.GET_LOCK_TYPE + query)
      .subscribe((res) => {
        this.locktypes = res.data;
      });
  }
  loadPage(page: number) {
    this.page = page;
    this.getList();
  }

  delete(): void {
    this.apiCallingService
      .callAPI(
        {},
        "DELETE",
        ConfigApi.URLS.SALE_PACKAGE.DELETE + "/" + this.detail._id
      )
      .subscribe((res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success("Successfully deleted");
          this.closeBtnClick();
          this.ngOnInit();
        }
      });
  }
  onSubmit(f: any) {
    const body = new HttpParams()
      .set("user_id", this.Globals.getUser()._id)
      .set("price", f.value.price)
      .set("name", "For " + f.value.type)
      .set("base_price", f.value.base_price)
      .set("type", f.value.type)
      .set(
        "pkg_desc",
        f.value.pkg_desc == "" || f.value.pkg_desc == null
          ? ""
          : f.value.pkg_desc
      )
      .set("vat", f.value.vat)
      .set("isExclusive", f.value.isExclusive);
    let url = ConfigApi.URLS.SALE_PACKAGE.CREATE;
    let method = "POST";

    if (this.modeltitle == "Edit Package") {
      url = ConfigApi.URLS.SALE_PACKAGE.UPDATE + "/" + this.detail._id;
      method = "POST";
    }
    this.apiCallingService.callAPI(body, method, url).subscribe({
      next: (res) => {
        if (this.apiCallingService.handleSuccess(res)) {
          this.toastr.success(res.msg);
          this.closeBtnClick();
          this.ngOnInit();
        }
      },
      error: (err) => {
        console.error(err, "error");
        this.toastr.error(
          err?.error.msg || "Something went wrong. Please try again."
        );
      },
    });
  }

  viewCouponList(data: any) {
    const packageId = data._id;
    this.router.navigate(["/user/sale-package/sale-coupons"], {
      queryParams: { pId: packageId },
    });
  }
}
